import {
  getAppLocation,
  logErrorToApplicationInsights,
  SettingsContext,
} from "adviesbox-shared";
import classnames from "classnames";
import React, { ReactElement, useContext } from "react";
import CurrentVestigingContext from "../current-vestiging/current-vestiging-context";
import { TegelProps } from "./tegel-types";
import classes from "./tegel.module.scss";

export const Tegel = ({
  code,
  caption,
  preSignedToken,
}: TegelProps): ReactElement => {
  const { vestiging } = useContext(CurrentVestigingContext);
  const settings = useContext(SettingsContext);
  const origin = getAppLocation(
    window.location.origin,
    "POR",
    code,
    settings.baseUrls
  );
  const baseUrlCodes = ["LIC"];
  let srcImg = "";
  try {
    srcImg = require(`../assets/Adviesbox_${code}.png`);
  } catch (e) {
    logErrorToApplicationInsights(Error(`Invalid Licentie-code - '${code}'.`));
    /* istanbul ignore if  */
    if (process.env.NODE_ENV === "development") {
      /* eslint-disable-next-line no-console */
      console.log(
        `Invalid Licentie-code - '${code}' voor productonderdeel - ${caption}.`
      );
    }
  }

  return (
    <>
      {srcImg && (
        <div className={classes.card_item}>
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              data-testid="tegel-link"
              id={`link-${caption}`}
              href={
                baseUrlCodes.some((productCode) => productCode === code)
                  ? origin
                  : code === "PRD"
                  ? `${settings.fdtaUrl}/rente?token=${preSignedToken}`
                  : `${origin}/vestiging/${vestiging.id}`
              }
              className={classnames(classes.card_startpage)}
            >
              <div className={classes.img_container}>
                <img className={classes.img} src={srcImg} alt="logo" />
                <div className={classes.text_container}>
                  <p className={classes.text_font}>{caption}</p>
                </div>
              </div>
            </a>
          </>
        </div>
      )}
    </>
  );
};
