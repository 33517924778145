import React, { ReactElement, useState } from "react";
import { Tegel } from "../../tegel";
import { LaatsteNieuwsCard } from "../../tegel/laatste-nieuws/laatste-nieuws-card";
import { RenteCard } from "../../tegel/rente-nieuws/rente-card";
import { StartSchermBodyProps } from "../infra";
import classes from "../startscherm.module.scss";

import NieuwsModal from "../news-modal/nieuws-modal";
import { Icon } from "adviesbox-shared";

enum SortOrderEnum {
  ADV,
  HDN,
  BER,
  PRD,
  DOC,
  LIC,
  INS
}
export const getGreeting = (): any => {
  const hour = new Date().getHours();

  const greetingsTimeframe = [
    { from: 6, to: 12, greeting: "Goedemorgen" },
    { from: 12, to: 18, greeting: "Goedemiddag" },
    { from: 18, to: 24, greeting: "Goedenavond" },
    { from: 0, to: 6, greeting: "Goedenacht" }
  ];

  for (let i = 0; i < greetingsTimeframe.length; i++) {
    if (hour >= greetingsTimeframe[i].from && hour < greetingsTimeframe[i].to) {
      return greetingsTimeframe[i].greeting;
    }
  }
  return "Goede dag";
};

const filterAndSortApplicatieOnderdelen = (
  productOnderdelen: any[],
  rechten: string[],
  isISWGebruiker: boolean,
  productonderdelenProduct: any[]
): any[] => {
  const filteredOnderdelen = productOnderdelen.filter(
    (po) => rechten.includes(po.code) || (po.code === "LIC" && isISWGebruiker)
  );
  if (isISWGebruiker) {
    const hasLic = filteredOnderdelen.find((po) => po.code === "LIC");
    if (!hasLic) {
      const licOnderdeel = productonderdelenProduct.find(
        (po) => po.code === "LIC"
      );
      filteredOnderdelen.push(licOnderdeel);
    }
  }
  filteredOnderdelen.sort(
    (a, b) => Number(SortOrderEnum[a.code]) - Number(SortOrderEnum[b.code])
  );
  return filteredOnderdelen;
};

export const StartschermBody = ({
  productOnderdelen,
  rechten,
  gebruikersNaam,
  preSignedToken,
  renteNieuwsLijst,
  isISWGebruiker,
  productonderdelenProduct,
  nieuws
}: StartSchermBodyProps): ReactElement => {
  const [applicatieOnderdelen] = useState(
    filterAndSortApplicatieOnderdelen(
      productOnderdelen,
      rechten,
      isISWGebruiker,
      productonderdelenProduct
    )
  );
  const privacyStatementUrl =
    "https://intersoftware.nl/media/1137/privacy-en-cookiestatement-intersoftware-extern-v70.pdf";

  const [nieuwsToShow, setNieuwsToShow] = useState<number | undefined>(
    undefined
  );
  return (
    <div className={classes.portaal}>
      <div className={classes.first_block}>
        <div className={classes.first_container}>
          <div className="text-center">
            <h3 className={classes.greetings}>
              {getGreeting()} {gebruikersNaam}
            </h3>
            <p className={classes.description}>
              Kies de applicatie die je wilt gebruiken.
            </p>
          </div>
          <div className="d-flex flex-wrap flex-row justify-content-center">
            {applicatieOnderdelen.map((po: any) => {
              return (
                <Tegel
                  key={`tegel-${po.naam}`}
                  code={po.code}
                  caption={po.naam}
                  preSignedToken={preSignedToken}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.second_block}>
        <div className={classes.second_block_container}>
          <div className="col-12 col-md-4">
            {applicatieOnderdelen.some((ao) => ao.code === "PRD") && (
              <RenteCard
                preSignedToken={preSignedToken}
                rentes={renteNieuwsLijst}
              />
            )}
          </div>
          <div className="col-12 col-md-8">
            <LaatsteNieuwsCard
              laatsteNieuws={nieuws}
              setNiewsToShow={setNieuwsToShow}
            />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <a
          href={privacyStatementUrl}
          data-testid="privacy-statement-link"
          id="privacy-statement-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy statement
          <Icon name="externallink" iconSize="m" className="pl-1"/>
        </a>
      </div>
      <NieuwsModal
        nieuwsToShow={nieuws?.find((x) => x?.id === nieuwsToShow)}
        handleClose={() => setNieuwsToShow(undefined)}
      />
    </div>
  );
};
