import { getFormattedDate, Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import "./laatste-nieuws-tegel.scss";
import { NieuwsItem } from "../../.generated/nieuws/nieuwstypes";
import { ZonedDateTime } from "@js-joda/core";
import ReactMarkdown from "react-markdown";

export const LaatsteNieuwsTegel = (props: {
  nieuws: NieuwsItem;
  setNiewsToShow: React.Dispatch<React.SetStateAction<number | undefined>>;
}): ReactElement => {
  const {
    id,
    titel,
    korteOmschrijving,
    startDatum: datum,
    categorie
  } = props.nieuws;

  return (
    <div className="laatste-nieuws-tegel" id={id.toString()}>
      <div className="laatste-nieuws-tegel__status">
        <span className="laatste-nieuws-tegel__icon">
          <Icon name="linkto" alt={categorie} iconSize={"xxl"} />
        </span>
      </div>
      <div className="laatste-nieuws-tegel__body">
        <a
          className="rente-tegel__link"
          data-testid="laatste-nieuws-link"
          href="/#"
          id="laatste-nieuws-link"
          onClick={() => props.setNiewsToShow(id)}
        >
          {titel}
          <Icon name="externallink" iconSize="m" className="pl-1"/>
        </a>
        <div className="laatste-nieuws-tegel__headline">
          <ReactMarkdown>{korteOmschrijving ?? ""}</ReactMarkdown>
        </div>
        <div
          className="laatste-nieuws-tegel__date"
          id={`rente-date-${id.toString()}`}
        >
          {getFormattedDate(ZonedDateTime.parse(datum).toLocalDate())}
        </div>
        <hr className="laatste-nieuws-tegel__hr" />
      </div>
    </div>
  );
};
