import { logErrorToApplicationInsights, PageLoading, SettingsContext, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext, useMemo, useState } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { FinDataNewsType, RenteFinDataType, StartSchermProps } from "../../infra";
import { Startscherm } from "../../startscherm";

type LoginToken = { token: string };

//TODO: this call should be done in the backend & the password needs to be encrypted
//TODO: check the possibility of using the localstorage to save the token data

const StartSchermMetFinDataLoginAjax = ({
  medewerkerId,
  productId,
  organisatieId,
  isISWGebruiker
}: StartSchermProps): ReactElement | null => {
  const [isAborted, setIsAborted] = useState(false);
  const settings = useContext(SettingsContext);
  const requestInit = useMemo(
    () => ({
      headers: {
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8"
      }
    }),
    [settings]
  );

  const preSignedTokenUrl = !isAborted
    ? `${settings.findataimportfromapiOrigin}/GetPreSignedToken`
    : /* istanbul ignore next */ null;
  const { data: preSignedTokenData, error: preSignedTokenError, loading: preSignedTokenLoading } = useAbortableFetch<
    LoginToken
  >(preSignedTokenUrl, requestInit);

  const latestNewsUrl = !isAborted
    ? `${settings.findataimportfromapiOrigin}/GetLatest`
    : /* istanbul ignore next */ null;
  const { data: latestNewsData, error: latestNewsError, loading: latestNewsLoading } = useAbortableFetch<
    FinDataNewsType
  >(latestNewsUrl, requestInit);

  if (typeof preSignedTokenData === "string" || preSignedTokenError) {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web - api '${preSignedTokenData}'. Resultaat bevat invalid data: string`)
    );
  }

  if (typeof latestNewsData === "string" || latestNewsError) {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web - api '${latestNewsData}'. Resultaat bevat invalid data: string`)
    );
  }

  /* istanbul ignore next */
  if (!preSignedTokenData || !latestNewsData) {
    setTimeout(() => {
      if (!isAborted) {
        setIsAborted(true);
      }
    }, 10000);
  }

  if (
    preSignedTokenLoading ||
    latestNewsLoading ||
    (!preSignedTokenData && /* istanbul ignore next */ !isAborted) ||
    (!latestNewsData && /* istanbul ignore next */ !isAborted)
  ) {
    return <PageLoading />;
  }

  let preSignedToken = null;
  let renteNieuwsLijst: RenteFinDataType[] = [];

  if (typeof preSignedTokenData !== "string" && preSignedTokenData?.token) {
    preSignedToken = preSignedTokenData.token;
  }

  if (typeof latestNewsData !== "string") {
    renteNieuwsLijst = latestNewsData?.latest || [];
  }

  return (
    <Startscherm
      productId={productId}
      organisatieId={organisatieId}
      medewerkerId={medewerkerId}
      renteNieuwsLijst={renteNieuwsLijst}
      preSignedToken={preSignedToken}
      isISWGebruiker={isISWGebruiker}
    />
  );
};

StartSchermMetFinDataLoginAjax.displayName = "StartSchermMetFinDataLoginAjax";

export default withErrorBoundary(StartSchermMetFinDataLoginAjax);
