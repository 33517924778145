import { NieuwsDetail } from "../infra";
import { SettingsType, hasJSONResponse } from "adviesbox-shared";
import { NieuwsItem } from "../../.generated/nieuws/nieuwstypes";

export const getNieuwsDetails = async (
  settings: SettingsType,
  nieuwsItem: NieuwsItem
): Promise<NieuwsDetail> => {
  const url = `${settings.nieuwsOrigin}/Nieuws/id/${nieuwsItem.id}`;

  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      Accept: "application/json"
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(
      `Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`
    );
  }
  const jsonResponse = await rsp.json();

  if (!jsonResponse.isValid)
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

  return {
    titel: nieuwsItem.titel ?? "",
    tekst: jsonResponse.nieuwsDetails.langeOmschrijving
  };
};
