import React, { ReactElement, ReactNode, useState } from "react";
import CurrentVestigingContext, { CurrentVestigingType } from "./current-vestiging-context";

const setContext = (
  vestiging: CurrentVestigingType["vestiging"],
  vestigingSetter: (vestiging: CurrentVestigingType["vestiging"]) => void
): CurrentVestigingType => ({
  vestiging,
  setVestiging(vestigingId, vestigingNaam): void {
    vestigingSetter({
      id: vestigingId,
      naam: vestigingNaam
    });
  }
});

const CurrentVestigingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [vestiging, setVestiging] = useState({
    id: "",
    naam: ""
  });
  const contextData = setContext(vestiging, setVestiging);

  return <CurrentVestigingContext.Provider value={contextData}>{children}</CurrentVestigingContext.Provider>;
};

export default CurrentVestigingProvider;
