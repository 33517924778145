import { createContext } from "react";

export const defaultSettings = {
  vestiging: {
    naam: "",
    id: ""
  },
  setVestiging: (_vestigingId: string, _vestigingNaam: string): void => {}
};

export type CurrentVestigingType = typeof defaultSettings;

const CurrentVestigingContext = createContext(defaultSettings);

export default CurrentVestigingContext;
