import { LocalDate, convert, ChronoUnit } from "@js-joda/core";

export const getFormattedDate = (input: LocalDate): string => {
  //format("DD-MM-YYYY")
  return convert(input)
    .toDate()
    .toLocaleDateString("nl-NL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
};

export const getFormattedDateMetLangeMaand = (input: LocalDate): string => {
  //).format("D MMMM Y");
  return convert(input)
    .toDate()
    .toLocaleDateString("nl-NL", {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
};

export const getFormattedDateTime = (input: Date): string => {
  //.format("DD-MM-YYYY HH:mm");
  return input.toLocaleDateString("nl-NL", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
};

export type YearMonth = {
  year: number;
  month: number;
};

const getDifference = (dateStart: LocalDate, dateEnd: LocalDate, calcWithFirstDayOfMonth?: boolean): number => {
  let date1 = dateStart;
  let date2 = dateEnd;

  if (calcWithFirstDayOfMonth) {
    date1 = date1.withDayOfMonth(1);
    date2 = date2.withDayOfMonth(1);
  }

  return date1.until(date2, ChronoUnit.MONTHS);
};

export const getDifferenceYearsMonths = (
  dateStart: LocalDate,
  dateEnd: LocalDate,
  calcWithFirstDayOfMonth?: boolean
): YearMonth => {
  const monthsTotal = getDifference(dateStart, dateEnd, calcWithFirstDayOfMonth);
  return { year: Math.floor(monthsTotal / 12), month: monthsTotal % 12 };
};

export function addYears(dt: LocalDate, numberOfYears: number): LocalDate {
  return dt.plusYears(numberOfYears);
}

export function addMonths(dt: LocalDate, numberOfMonths: number): LocalDate {
  return dt.plusMonths(numberOfMonths);
}
