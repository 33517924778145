import { Icon, SettingsContext } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { RenteTegel } from "./rente-tegel";
import './rente-card.scss';
import { RenteFinDataType } from "../../start-scherm/infra";

export const RenteCard = (props: { rentes: RenteFinDataType[]; preSignedToken: string | null }): ReactElement => {
  const { rentes, preSignedToken } = props;
  const settings = useContext(SettingsContext);

  //const footerLink = `https://adviesboxaccept.fdta.nl/rente?token=${preSignedToken}&url=nieuws`;
  const footerLink = `${settings.fdtaUrl}/rente?token=${preSignedToken}&url=nieuws`;
  return (
    <div className="rente-card">
        <h3 className="rente-card__header" id="rente-nieuws-header">
            Rentenieuws
        </h3>
        {!!rentes.length && (
            <div className="rente-card__body">
                {rentes.map((rente, i) => (
                    <RenteTegel
                        id={`rente-nieuws-${i}`}
                        key={i}
                        date={rente.date}
                        pdfUrl={rente.pdfUrl}
                        type={rente.type}
                        label={rente.label}
                    />
                ))}
            </div>
        )}
        {!rentes.length && (
            <div className="rente-card__body rente-card__body--empty">
                Er zijn nog geen nieuwsberichten gepubliceerd.
            </div>
        )}
        <div className="rente-card__footer">
            <a
                href={footerLink}
                data-testid="rente-nieuws-footer"
                id="rente-nieuws-footer"
                target="_blank"
                rel="noopener noreferrer"
            >
                Volledige rentenieuws bekijken
                <span>
                    <Icon name="externallink" alt="externallink" iconSize="s" className="laatste-nieuws-card__icon" />
                </span>
            </a>
        </div>
    </div>
  );
};
