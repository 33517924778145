export function mapDl2Ui<T>(object: any, key: string, multiple = false, id?: string): T {
  const data = id
    ? object && object.isValid && object[key] && object[key][id]
      ? object[key][id]
      : null
    : object && object.isValid && object[key]
    ? object[key]
    : null;

  return multiple && data ? Object.values(data) : data;
}
