import { AuthContext, LoginPage, SigninOidc, useUpscope } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Route } from "react-router";
import logo from "../assets/new-brand-logo.svg";
import StartschermAjax from "../start-scherm/startscherm-ajax";

const PortalApp = (): ReactElement => {
  const { user } = useContext(AuthContext);
  useUpscope(user?.profile.name || "unknown");

  if (user) {
    return (
      <div data-testid="startscherm">
        <Route path="/" exact component={StartschermAjax} />
        <Route path="*" />
      </div>
    );
  }

  return (
    <>
      <Route path="/signin-oidc" component={SigninOidc} />
      <LoginPage appLogo={logo} />
    </>
  );
};

PortalApp.displayName = "PortalApp";

export default PortalApp;
