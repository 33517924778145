/* istanbul ignore file */

import React, { useEffect, useState, ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import { NieuwsDetail } from "../infra";
import ReactMarkdown from "react-markdown";
import { getNieuwsDetails } from "./news-modal-api";
import { NieuwsItem } from "../../.generated/nieuws/nieuwstypes";
import { useRequestInit } from "adviesbox-shared";
import classes from "./nieuws-modal.module.scss";

export type NieuwsModalProps = {
  nieuwsToShow: NieuwsItem | undefined;
  handleClose: () => void;
};

const NieuwsModal = ({
  nieuwsToShow,
  handleClose
}: NieuwsModalProps): ReactElement => {
  const [nieuwsDetails, SetNieuwsDetails] = useState<NieuwsDetail | null>(null);
  const { settings } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
  }>();
  useEffect(() => {
    // fetch nieuwsDetails based on nieuwsToShow
    if (nieuwsToShow?.id === undefined) return;
    const fetchNieuwsDetails = async (): Promise<void> => {
      SetNieuwsDetails(await getNieuwsDetails(settings, nieuwsToShow));
    };
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    fetchNieuwsDetails();
  }, [nieuwsToShow, settings]);

  return (
    <Modal
      size="lg"
      show={nieuwsToShow !== undefined}
      onHide={handleClose}
      dialogClassName={classes.nieuwsModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{nieuwsToShow?.titel}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.nieuwsModalContent}>
        <ReactMarkdown>{nieuwsDetails?.tekst ?? ""}</ReactMarkdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NieuwsModal;
