/* istanbul ignore file */

import { hasNoRoleIsw, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { MedewerkerOrganisatieOutput, Product, ProductenOutput } from "../.generated/licenties/licentiestypes";
import UserDetailsContext from "../user-details/user-details-context";
import { mapDl2Ui } from "./infra/startscherm-mapper";
import StartSchermMetFinDataLoginAjax from "./startscherm-body/finDataConfig/startscherm-met-findata-ajax";

const StartschermAjax = (): ReactElement | null => {
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const { settings, user, requestInit } = useRequestInit();
  const medewerkerUrl = user ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const productenUrl = `${settings.licentiesOrigin}/Producten`;
  const medewerker = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);
  const producten = useAbortableFetch<ProductenOutput>(productenUrl, requestInit);

  useEffect(() => {
    if (
      !medewerker ||
      !medewerker.data ||
      typeof medewerker.data === "string" ||
      !medewerker.data.medewerkerId ||
      !medewerker.data.organisatieId ||
      !medewerker.data.medewerker ||
      medewerker.data.medewerkerId === userDetails.medewerkerId
    )
      return;

    setUserDetails({
      ...medewerker.data.medewerker,
      medewerkerId: medewerker.data.medewerkerId,
      organisatieId: medewerker.data.organisatieId
    });
  });

  if (producten.error || medewerker.error) {
    throw Error("Helaas er is iets mis gegaan bij het ophalen van de data");
  }

  if (medewerker.loading || producten.loading || !medewerker.data || !producten.data) {
    return <PageLoading />;
  }

  const productenData = mapDl2Ui<{ [key: string]: Product }>(producten.data, "producten", false);
  const medewerkerId = mapDl2Ui<string>(medewerker.data, "medewerkerId", false);
  const organisatieId = mapDl2Ui<string>(medewerker.data, "organisatieId", false);
  const productId = Object.keys(productenData).find((key: string) => productenData[key].code === "ABX");

  if (!productId || !organisatieId || !medewerkerId) {
    throw Error("Er missen een of meerdere ids");
  }

  const isISWGebruiker = !hasNoRoleIsw(user);

  return (
    <>
      <StartSchermMetFinDataLoginAjax
        productId={productId}
        organisatieId={organisatieId}
        medewerkerId={medewerkerId}
        isISWGebruiker={isISWGebruiker}
      />
    </>
  );
};

export default withErrorBoundary(StartschermAjax);
