import { Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { LaatsteNieuwsTegel } from "./laatste-nieuws-tegel";
import "./laatste-nieuws-card.scss";
import { NieuwsItem } from "../../.generated/nieuws/nieuwstypes";

export const LaatsteNieuwsCard = (props: {
  laatsteNieuws: NieuwsItem[];
  setNiewsToShow: React.Dispatch<React.SetStateAction<number | undefined>>;
}): ReactElement => {
  const { laatsteNieuws, setNiewsToShow } = props;
  const footerLink = "https://intersoftware.nl/nieuwsoverzicht/";

  return (
    <div className="laatste-nieuws-card">
      <h3 className="laatste-nieuws-card__header" id="rente-nieuws-header">
        Laatste nieuws
      </h3>

      {!!laatsteNieuws.length && (
        <div className="laatste-nieuws-card__body">
          {laatsteNieuws.map((nieuws) => (
            <LaatsteNieuwsTegel
              key={`lnt-${nieuws.id}`}
              nieuws={nieuws}
              setNiewsToShow={setNiewsToShow}
            />
          ))}
        </div>
      )}
      {!laatsteNieuws.length && (
        <div className="laatste-nieuws-card__body laatste-nieuws-card__body--empty">
          <p>
            <b>Welkom bij BLINQX Hypotheek.</b>
            <br />
            <br />
            Adviesbox heeft een facelift gehad, maar werkt nog precies
            hetzelfde.
            <br />
            Omdat Adviesbox onderdeel is van eBlinqx, heeft de software vanaf nu
            namelijk een eBlinqx jasje aan.
            <br />
            Dit nieuwe uiterlijk is moderner, rustiger en sluit aan bij de
            andere state-of-the-art software van Blinqx.
          </p>
        </div>
      )}
      {!laatsteNieuws.length && (
        <div className="laatste-nieuws-card__footer">
          <a
            href={footerLink}
            data-testid="rente-nieuws-footer"
            id="rente-nieuws-footer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nieuwsoverzicht bekijken
            <span>
              <Icon
                name="externallink"
                alt="externallink"
                iconSize="s"
                className="laatste-nieuws-card__icon"
              />
            </span>
          </a>
        </div>
      )}
    </div>
  );
};
