import { getEnvironment } from "../../utils/environment-helper";

// use altTitle when a page has no NavigationList (side menu) loaded (ie. zoekscherm::Advies).
type TitleProps = { appName: string; altTitle?: string };
export const Title = ({ appName, altTitle }: TitleProps): null => {
  const hostnamePrefix = getEnvironment(window.location.hostname)
    ? `${getEnvironment(window.location.hostname)}\xa0`
    : /* istanbul ignore next */ "";
  const altPageTitel = `${altTitle ? `${altTitle}\xa0` : /* istanbul ignore next */ ""}`;

  window.document.title = hostnamePrefix + altPageTitel + `|\xa0${appName}`;

  return null;
};
