import React, { ReactElement } from "react";
import './rente-tegel.scss';
import { Icon, IconType } from "adviesbox-shared";
import { RenteFinDataType } from "../../start-scherm/infra";
import { ZonedDateTime } from "@js-joda/core";
import { getFormattedDate } from "../../shared/utils/dates";

export const RenteTegel = (props: RenteFinDataType & { id: string }): ReactElement => {
  const { id, label, date: dateTime, pdfUrl, type } = props;

  let iconName: IconType = "picture";
  let backgroundClass = "rente-tegel__icon "
  if (type === "verhoging") {
    iconName = "higherinterest";
    backgroundClass += "rente-tegel__icon--rood";
  }
  if (type === "verlaging") {
    iconName = "lowerinterest";
    backgroundClass += "rente-tegel__icon--groen";
  }
  if (type === "wijziging") {
    iconName = "higherlowerinterest";
    backgroundClass += "rente-tegel__icon--geel";
  }

  return (
    <div className="rente-tegel" id={id}>
        <div className="rente-tegel__status">
            <span className={backgroundClass}>
                <Icon name={iconName} alt={type} iconSize={"xxl"} />
            </span>
        </div>
        <div className="rente-tegel__body">
            <a
                className="rente-tegel__link"
                data-testid="rente-link"
                id="rente-link"
                href={pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
                <Icon name="externallink" iconSize="m" className="pl-1"/>
            </a>
            <div className="rente-tegel__date" id="rente-date">
                {getFormattedDate(ZonedDateTime.parse(dateTime).toLocalDate())}
            </div>
            <hr className="rente-tegel__hr" />
        </div>
    </div>
  );
};
